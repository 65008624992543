<template>
  <div style="padding-bottom: 100px;background-color: #efefef;">
    <div class="container-md p-5 mb-5 contactus">
      <!-- <div class="row mb-2">
        <div class="col-4 text-end fw-lighter">WhatsApp: </div>
        <div class="col-8" role="button" @click="onClickWhatsapp">
          <div>+85259897756</div>
          <div>
            <img src="@/assets/pages/whatsapp_click.png" alt="" style="width: 160px">
          </div>
        </div>
      </div> -->
      <div class="row mb-2">
        <div class="col-4 text-end fw-lighter">Service: </div>
        <div class="col-8" role="button" >
          <button
          type="button"
          class="btn btn-success"
          @click="onClickChat"
        ><img src="@/assets/pages/service.png" style="width: 36%;"> Chat        </button>
        </div>
      </div>
      <div class="row  mb-2">
        <div class="col-4 text-end fw-lighter">{{$t('contactus.wechatid')}}: </div>
        <div class="col-8">bnbvet_com</div>
      </div>
      <div class="row mb-2">
        <div class="col-4 text-end fw-lighter">EMAIL: </div>
        <div class="col-8" role="button" @click="onClickEmail">admin@bnbvet.com</div>
      </div>
      <!-- <div class="row mb-2">
        <div class="col-4 text-end fw-lighter">{{$t('hotline')}}: </div>
        <div class="col-8" role="button" @click="onClickPhone">
          <div>+85259897756</div>
          <div>+8619518751359</div>
        </div>
      </div> -->
        <div class="row mb-2">
          <div class="col-4 text-end fw-lighter">{{ $t('contactus.address') }}: </div>
          <div class="col-8" role="button">
            {{ $t('contactus.address2') }}
          </div>
        </div>

      <div class="row mb-5"></div>
      <div class="row">
        <div class="col-md-12 text-center">
          <textarea class="textarea" :placeholder="$t('message')" v-model="message"></textarea>
        </div>
      </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <input class="input" :placeholder="$t('contactInformation')" v-model="email">
          </div>
        </div>
      <div class="row mt-2">
        <div class="col-md-12 text-center">
          <button type="button" @click="onClickCancel" class="btn btn-primary  mb-3 me-2 ps-5 pe-5">{{ $t('login.cancel') }}</button>
          <button type="button" @click="onClickConfirm" class="btn btn-success mb-3 ps-5 pe-5">{{$t('login.confirm')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  // eslint-disable-next-line
  name: 'contactus-index',
  components: {
  },
  data() {
    return {
      message: '',
      email: '',
    };
  },
  methods: {
    onClickChat() {
      // this.$router.push('/chatbot.html');
      window.location.href = 'https://www.bnbvet.com/chatbot.html';
    },
    onClickWhatsapp() {
      let url = 'https://wa.me/85259897756';
      if (typeof plus == 'object') {
        // eslint-disable-next-line
        plus.runtime.openURL(url, (res) => {
          // console.log(res);
        });
      } else {
        window.open(url, '_blank');
      }
    },
    onClickPhone() {
      let url = 'tel://0085259897756';
      if (typeof plus == 'object') {
        // eslint-disable-next-line
        plus.runtime.openURL(url, (res) => {
          // console.log(res);
        });
      } else {
        window.open(url, '_blank');
      }
    },
    onClickEmail() {
      let url = 'mailto:admin@bnbvet.com';
      if (typeof plus == 'object') {
        // eslint-disable-next-line
        plus.runtime.openURL(url, (res) => {
          // console.log(res);
        });
      } else {
        window.open(url, '_blank');
      }
    },
    onClickCancel() {
      this.$router.push('/');
    },
    async onClickConfirm() {
      if (this.email == '' && this.$store.state.hasLogin == false) {
        this.$toast.show({ content: this.$t('messageError') });
        return;
      }
      if (this.message) {
        // console.log(1111, this.message);
        let res = await this.$api.site.message({ message: this.message, email: this.email, user_id: this.$store.state.user.id });
        if (res.data && res.data.code == 200) {
          this.email = '';
          this.message = '';
          this.$toast.show({ content: this.$t('messageSuccess') });
        } else {
          this.$toast.show({ content: res.data.msg });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contactus{
    font-size: 18px;

  .textarea{
    padding: 10px;
    width: 70vw;
    height: 30vh;
  }
  .input{
    padding: 10px;
    width: 70vw;
  }

}
@media (max-width: 767.98px) {
.contactus{
  padding-left: 10px !important ;
  padding-right: 10px !important ;
  .textarea, .input{
    width: 100%;
  }
}
}
</style>
